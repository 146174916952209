import ApiService from "@/core/services/api.service.js";

const categoryService = {
    getCategories() {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/categories")
            .then(function(response) {
              if (response.data) {
                resolve(response.data[0]);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    getCategoryTree() {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/categories/listTree")
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    getCategoryById(id) {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/categories/detail/"+id)
            .then(function(response) {
              if (response.data) {
                resolve(response.data.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    createCategory(data) {
        return  new Promise((resolve, reject) => {
          ApiService.post("api/admin/categories/create",data)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    updateCategory(data,id) {
        return  new Promise((resolve, reject) => {
          ApiService.post("api/admin/categories/update/"+id,data)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    deleteCategory(id) {
        return  new Promise((resolve, reject) => {
          ApiService.delete("api/admin/categories/delete/"+id)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },

}

export default categoryService;