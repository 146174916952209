<template>
  <div>
    <b-card title="" header-tag="header" class="card-custom">
      <template v-slot:header>
        <div class="card-title m-0">
          <h3 class="card-label m-0">{{ title }}</h3>
        </div>
        <div class="card-toolbar"></div>
      </template>

      <form class="form" novalidate="novalidate" id="product_offer">
        <b-row>
          <b-col md="4">
            <b-form-group label="Apply offer to products by"  class="fw-600">
              <b-form-select
                v-model="form.product_select_type"
                name="productSelectType"
                :options="productSelectTypeList"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-show="form.product_select_type == 'category'">
          <b-col md="4">
            <b-form-group label="Categories">
              <b-form-select
                multiple
                v-model="form.category"
                name="category"
                :options="categories"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-show="form.product_select_type == 'price_range'">
          <b-col md="4">
            <b-form-group label="Min Price">
              <b-form-input
                min="0"
                v-model="form.min_val"
                type="number"
                name="min"
                class="form-control"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Max price">
              <b-form-input
                min="0"
                v-model="form.max_val"
                type="number"
                name="max"
                class="form-control"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <div v-show="form.product_select_type == 'products'">
          <div class="d-flex justify-content-between pb-3 align-items-center">
            <h5 class="m-0 p-0">Add Product</h5>
          </div>
          <div class="d-flex justify-content-between align-items-center ml-3 pb-3">
            <!-- <b-form-checkbox value="me" size="lg" class="font-weight-bold"
              >Select All</b-form-checkbox
            >
            <div class="d-flex align-items-center">
              <div class="input-group mb-0 mr-3 pl-0" style="width: 250px">
            
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <i class="las la-search"></i>
                  </div>
                </div>
                <input
                  type="search"
                  autocomplete="off"
                  class="form-control"
                  placeholder="Search Products"
                />
              </div>
              
            </div> -->
            <b-button variant="primary" @click="$bvModal.show('offer-product')"
              ><i class="las la-plus mr-2"></i>Add Product</b-button
            >
          </div>

          <div class="border offer-list mb-4">
            <perfect-scrollbar
              class="aside-menu scroll bg-white"
              style="max-height: 250px; position: relative"
            >
              <div
                class="item d-flex row mx-0"
                v-for="(product, i) in selectedProducts"
                :key="`selectedProduct-${product.id}`"
              >
                <!-- <div class="border-right pl-3 pt-2" style="width: 40px">
                  <b-form-checkbox value="me" size="lg"></b-form-checkbox>
                </div> -->
                <div class="border-right pl-" style="width: 80px">
                  <div class="p-2">
                    <img
                      v-if="product.images == null"
                      src="@/custom/media/dummy/frame.jpg"
                      width="50"
                    />
                    <img
                      v-else
                      :src="imgPath + product.images.image"
                      alt="..."
                      width="50"
                    />
                  </div>
                </div>
                <div class="col-2 border-right">
                  <div class="p-2 pt-3">{{ product.name }}</div>
                </div>
                <div class="col-2 border-right" style="max-width: 150px">
                  <div class="p-2 pt-3">{{ product.model }}</div>
                </div>
                <div class="col-auto ml-auto">
                  <div class="p-2 pb-0 text-right">
                    <b-button class="btn-icon p-1 w-auto h-auto" @click="deleteProduct(i)"
                      ><i class="las la-trash-alt"></i
                    ></b-button>
                  </div>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
        <b-row v-show="form.product_select_type == 'model'">
          <b-col md="12">
            <b-form-group label="Type model number using comma seperators">
              <b-form-input
                v-model="form.model"
                type="text"
                name="model"
                class="form-control"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <button ref="kt_product_offer_submit" class="btn btn-primary">
              <i class="las la-save"></i> Save
            </button>
          </b-col>
        </b-row>
      </form>
    </b-card>

    <!-- Begin: Offer Product -->
    <b-modal
      id="offer-product"
      centered
      hide-footer
      hide-header
      size="xl"
      modal-class="add-product-modal"
    >
      <template #default="{ hide }">
        <div class="text-right">
          <i class="las la-times cursor-pointer" @click="hide(), addProducts()"></i>
        </div>
        <div class="row mb-4">
          <div class="col-4">
            <b-form-input
              id="input-1"
              @change="getSearchProductList"
              v-model="searchKeyWord"
              type="text"
              placeholder="Search Product"
              required
              size="sm"
            ></b-form-input>
          </div>

          <div class="col-4"></div>
        </div>
        <div class="px-2 pb-4 pl-6">
          <b-form-checkbox v-model="selectAll" :value="true" size="lg"
            >Select All</b-form-checkbox
          >
        </div>
        <b-form-checkbox-group
          id="checkbox-group-2"
          v-model="selectedProducts"
          name="flavour-2"
        >
          <div class="row mx-0 px-0">
            <div
              class="col-lg-4 col-md-6 pb-3 px-2"
              v-for="product in searchProducts"
              :key="`prd-${product.id}`"
            >
              <div class="d-flex border py-2 mx-0">
                <div style="width: 50px" class="px-2 text-center">
                  <b-form-checkbox :value="product" size="lg"></b-form-checkbox>
                </div>
                <div class="pl-0" style="width: 60px">
                  <img
                    v-if="product.images == null"
                    src="@/custom/media/dummy/frame.jpg"
                    width="50"
                  />
                  <img
                    v-else
                    :src="imgPath + product.images.image"
                    alt="..."
                    width="50"
                  />
                </div>
                <div class="px-2 flex-fill">{{ product.name }}</div>
                <div style="width: 120px" class="pl-0 pr-3 text-right">
                  {{ product.model }}
                </div>
              </div>
            </div>
          </div>
        </b-form-checkbox-group>

        <div class="text-right">
          <hr />
          <button class="btn btn-primary" @click="addProducts(), hide()">
            Add products
          </button>
        </div>
      </template>
    </b-modal>
    <!-- End: Offer Product -->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Editor from "primevue/editor";
import Calendar from "primevue/calendar";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import OfferService from "@/core/services/api/offer";
import CategoryService from "@/core/services/api/category";

import Swal from "sweetalert2";
export default {
  components: { Editor, Calendar },
  data() {
    return {
      id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
      categories: [],
      title: this.$route.params.id ? "Update Offer" : "New offer",
      productSelectTypeList: [
        { value: null, text: "Select a product select type" },
        { value: "category", text: "category" },
        { value: "price_range", text: "Price" },
        { value: "products", text: "Products" },
        { value: "model", text: "Products by model No." },
      ],
      loading: false,
      selection: null,
      imgPath: process.env.VUE_APP_API_STORAGE_URL,
      form: {
        id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
        category: [],
        product_select_type: null,
        min_val: null,
        max_val: null,
        products: [],
      },
      searchProducts: [],
      selectedProducts: [],
      searchKeyWord: "",
      selectAll: false,
    };
  },
  mounted() {
    var vm = this;
    this.getCategories();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
        route: "/dashboard",
      },
      {
        title: "Offers",
        route: "/offers",
      },
      {
        title: "Offer : New",
      },
    ]);
    const signin_form = KTUtil.getById("product_offer");
    this.fv = formValidation(signin_form, {
      fields: {
        productSelectType: {
          validators: {
            notEmpty: {
              message: "Product select type is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
    this.fv.on("core.form.valid", async () => {
      switch (vm.form.product_select_type) {
        case "price_range":
          if (vm.form.min_val == null && vm.form.max_val == null) {
            Swal.fire("Min and max price is required");
            return;
          }
          break;
        case "category":
          if (vm.form.category.length == 0) {
            Swal.fire("Category are required");
            return;
          }
          break;
        case "products":
          if (vm.form.products.length == 0) {
            Swal.fire("Products are required");
            return;
          }
          break;
        case "model":
          if (!vm.form.model) {
            Swal.fire("Models ars required");
            return;
          }
          break;
      }
      // set spinner to submit button
      const submitButton = this.$refs["kt_product_offer_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right", "disabled");

      const formData = new FormData();
      formData.append("offer", JSON.stringify(vm.form));

      await this.addProductToOffer(formData, vm.form.id, function (response) {
        vm.response(response, submitButton);
      });
    });

    this.getSearchProductList();
  },
  methods: {
    async getSearchProductList() {
      this.searchProducts = [];
      var response = await OfferService.getProductsByKeyword(this.searchKeyWord);
      var selectedIds = [];
      this.selectedProducts.map((e) => {
        this.searchProducts.push(e);
        selectedIds.push(e.id);
      });
      response.data.map((e) => {
        if (!selectedIds.includes(e.id)) {
          this.searchProducts.push(e);
        }
      });
    },
    async getCategories() {
      var response = await CategoryService.getCategories();
      response.data.map((e) => {
        this.categories.push({ value: e.id, text: e.name });
      });
    },
    response(response, submitButton) {
      var vm = this;
      if (response) {
        let timerInterval;
        Swal.fire({
          title: response.message,
          timer: 500,
          icon: "success",
          timerProgressBar: true,
          showConfirmButton: false,
          showCancelButton: false,
          didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector("b");
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft();
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
          vm.$router.push({ name: "offer-form-edit", param: { id: vm.id } });
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
          }
        });
      }
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right",
        "disabled"
      );
    },

    addProductToOffer(formData, id, callback) {
      var vm = this;
      OfferService.addProductsToOffer(id, formData)
        .then(function (response) {
          callback(response);
        })
        .catch((err) => {
          callback(false);
        });
    },
    addProducts() {
      var vm = this;

      vm.form.products = [];
      vm.selectedProducts.map((e) => {
        if (!vm.form.products.includes(e.id)) {
          vm.form.products.push(e.id);
        }
      });
    },
    deleteProduct(index) {
      this.selectedProducts.splice(index, 1);
    },
  },
  watch: {
    selectAll(val) {
      this.selectedProducts = [];
      if (val) {
        this.searchProducts.map((e) => {
          this.selectedProducts.push(e);
        });
      }
    },
  },
};
</script>

<style></style>
